import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { formatDate } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { CadFormComponent } from '../../shared/dialogs/cad-form/cad-form.component';
import { CadBulkEditComponent } from '../../shared/dialogs/cad-bulk-edit/cad-bulk-edit.component';
import { DeleteComponent } from '../../shared/dialogs/delete/delete.component';
import { ConfirmComponent } from '../../shared/dialogs/confirm/confirm.component';
import { FilterArrayPipe } from '../../pipes/custom/filter-array.pipe';
import { FilterPipe } from '../../pipes/custom/filter.pipe';
import * as Highcharts from 'highcharts';

import { AuthenticationService } from '../../core/services/authentication.service';

export interface OrderData {
  id: string;
  area: any;
  sale: string;
  despatch: string;
  delivery: string;
  customer: string;
  address: string;
  town: string;
  postcode: string;
  weight: string;
  parcel: string;
  loaded: string;
  over: string;
  hrw: string;
  w3w: string;
  surname: string;
  image: string;
  scanned: string;
  delivered: string;
  vans: any;
}

@Component({
  selector: 'app-cadrkdata',
  templateUrl: './cadrkdata.component.html',
  styleUrls: ['./cadrkdata.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CadrkdataComponent implements AfterViewInit{

  displayedColumns: string[] = ['select', 'area', 'sale', 'despatch', 'delivery', 'surname', 'w3w', 'image', 'customer', 'address', 'postcode', 'weight', 'parcel', 'loaded', 'over', 'scanned', 'delivered', 'vans', 'hrw'];
  dataSource: MatTableDataSource<OrderData>;
  selection = new SelectionModel<OrderData>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  maintenance:boolean = false;
  user:any = {'id':0, 'username':''};
  url:string;
  allOrders:any=[];
  orders:any=[];
  areas:any=[];
  vans:any=[];
  rkcolour:any=[];
  rkcpr:any = '';
  van:any = '';
  area:any;
  today:any;
  uptime:any;
  date:any = new Date();
  now: Date = new Date();
  total:number=0;
  forward:number=0;
  scanned:number=0;
  scannedp:number=0;
  delivered:number=0;
  deliveredp:number=0;
  day1:number=0;
  day2:number=0;
  day3:number=0;
  day1p:number=0;
  day2p:number=0;
  day3p:number=0;

  cprChart:any = {   
    chart: {
      type: 'column',
      backgroundColor: '#58595b',
      height: 300,
    },
    title: {
      text: 'CPR THIS MONTH',
      style: {
        color:'#ffffff',
        fontSize: '26px !important',
      }
    },
    legend : {
       layout: 'vertical',
       align: 'left',
       verticalAlign: 'top',
       x: -150,
       y: 500,
       floating: true,
       borderWidth: 1,
       backgroundColor:'#58595b'
    },
    xAxis:{
       categories: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30'],
       labels: {
            style: {
                color: 'white'
            }
        }
    },
    yAxis : {
      title: {
          text: ''
      },
      labels: {
            style: {
                color: 'white'
            }
      },
      max:40,
      plotLines: [{
          value: 15,
          color: 'red',
          width: 3,
          label: {
              text: 'Theoretical mean: 932',
              align: 'right',
              style: {color: 'white'}
          }
      }]
    },
    tooltip : {
       shared: true, valueSuffix: ' '
    },
    plotOptions : {
       area: {
          fillOpacity: 0.5 
       }
    },
    credits:{
      enabled: false
    },
    colors: ['#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200', '#ff0200'],
    series: [
       {
          name: 'CPR',
          colorByPoint: true,
          data: [0, 0, 90, 0, 40, 0, 20, 0, 0, 0, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          // backgroundColor:'#fdd7af'
       }
    ]
  };

  constructor(private auth:AuthenticationService, private _liveAnnouncer: LiveAnnouncer, public dialog: MatDialog, private spinner: NgxSpinnerService, private _snackBar: MatSnackBar, private filterPipe:FilterPipe, private filterArray:FilterArrayPipe){
    this.user = JSON.parse(localStorage.getItem('profile'));
    if(localStorage.getItem('rkdate') != null){
      this.date = new Date(localStorage.getItem('rkdate'));
    }
    this.today = formatDate(this.date, 'yyyy-MM-dd', 'en');
    this.dataSource = new MatTableDataSource();
    this.url = this.auth.apiUrl;
    setInterval(() => {
      this.now = new Date();
    }, 1);
  }

  ngAfterViewInit() {
    this.spinner.show();
    this.auth.getAPI('rkarea/').subscribe(result => {
      this.areas = result['results'];
    });
    this.auth.getAPI('rkvan/').subscribe(result => {
      this.vans = result['results'];
    });
    this.auth.getAPI('cadrktime/?date='+this.today).subscribe(result => {
      if(result['count'] == 0){
        this.uptime = undefined;
      }else{
        this.uptime = result['results'][0]['time'];
      }
    });
    this.auth.getAPI('rkdataes/?limit=800&despatch='+this.today).subscribe(result => {
      this.allOrders = result['results'];
      this.orders = result['results'];
      this.datacal();
      this.dataSource = new MatTableDataSource(this.orders);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.hide();
    });
    this.auth.getAPI('rkcolour/').subscribe(result => {
      this.rkcolour = result['results'];
    });
    this.cprdata();
  }

  utime(){
    // console.log(formatDate(new Date(), 'yyyy-MM-dd', 'en'), '===',this.uptime);
    if(this.uptime == undefined){
      this._snackBar.open("Please select Correct Time",'', {duration: 5000, panelClass: ['alertSnackbar']});
      return false;
    }
    const dialogRef = this.dialog.open(ConfirmComponent,{
      data:{
        data:'',
        title:"You can only do this ONCE per day. Are you sure you wish to update today's Lorry List"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        this.auth.postAPI('cadrktime/',{'date':formatDate(new Date(), 'yyyy-MM-dd', 'en'),'time':this.uptime+':00', 'user':this.user.id})
        // .pipe(catchError((error:any, caught: Observable<any>) => {
        //   console.log(error);
        // }))
        .subscribe(res =>{
            this._snackBar.open("Updated Time Stored Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
            this.spinner.hide();
          },
          error => {
            // console.log(error);
            this._snackBar.open("Time Already Updated for Today",'', {duration: 5000, panelClass: ['alertSnackbar']});
            this.spinner.hide();
          }
        )
      }
    });
  }

  export(){
    // console.log(this.area, this.today);
    this.auth.getAPI('rkdata-export/?despatch='+this.today+'&area='+this.area?.id).subscribe((result:any)=>{
      if(result['id']>0){
        this._snackBar.open("Download Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
      }
    })
  }

  dupdate(event:any,ordr:any){
    // this.spinner.show();
    var ddt = formatDate(event.value, 'yyyy-MM-dd', 'en');
    // console.log(ddt, event,ordr);
    this.auth.patchAPI('rkdata/'+ordr.id+'/',{'delivery':ddt}).subscribe(result =>{
        let indexx = this.orders.findIndex(x => x.id === result.id);
        this.orders[indexx]['deadline'] = result['deadline'];
        this.dataSource = new MatTableDataSource(this.orders);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this._snackBar.open("EST DEL DATE Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
    })
    // this.auth.getAPI('rkdata/?limit=800&despatch='+this.today+'&area='+ara).subscribe(result => {
    //   this.allOrders = result['results'];
    //   this.orders = result['results'];
    //   this.datacal();
    //   this.dataSource = new MatTableDataSource(this.orders);
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    //   this.spinner.hide();
    // });
  }

  dchange(event:any){
    this.spinner.show();
    localStorage.setItem('rkdate', event.value);
    this.today = formatDate(event.value, 'yyyy-MM-dd', 'en');
    var ara = this.area?.id;
    if(ara == undefined){ara=''}
    // this.auth.getAPI('rkdata/?limit=800&despatch='+this.today+'&area='+ara).subscribe(result => {
    this.auth.getAPI('rkdata/?limit=800&despatch='+this.today+'&area='+ara).subscribe(result => {
      this.allOrders = result['results'];
      this.orders = result['results'];
      this.datacal();
      this.dataSource = new MatTableDataSource(this.orders);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.hide();
    });
    
    this.auth.getAPI('cadrktime/?date='+this.today).subscribe(result => {
      if(result['count'] == 0){
        this.uptime = undefined;
      }else{
        this.uptime = result['results'][0]['time'];
      }
    });
    this.cprdata();
  }

  vanFilter(){
    // this.orders = this.allOrders.filter((el:any)=>{
    //   return this.van ? (el.vans?.id==this.van?.id) : true;
    // });
    
    this.orders = this.allOrders.filter((el:any)=>{
      // return this.van ? (el.vans?.title.toLocaleLowerCase().includes(this.van.toLocaleLowerCase())) : true;
      return this.van && el.vans ? (el.vans?.title.toLocaleLowerCase().includes(this.van.toLocaleLowerCase())) : true;
    });
    this.dataSource = new MatTableDataSource(this.orders);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.datacal();
  }

  areaFilter(){
    this.spinner.show();
    var ara = this.area.id;
    if(ara == undefined){ara = '';}
    // this.orders = this.filterArray.transform(this.orders,this.area.title, 'areas');
    // this.dataSource = new MatTableDataSource(this.orders);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.auth.getAPI('rkdata/?limit=800&despatch='+this.today+'&area='+ara).subscribe(result => {
      this.allOrders = result['results'];
      this.orders = result['results'];
      this.datacal();
      this.dataSource = new MatTableDataSource(this.orders);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.hide();
    });
    this.cprdata();
  }

  upload(file:any){
    this.auth.postForm('cadrk-upload/',[{'file':file.target.files[0]},{'user':this.user.id}]).subscribe((result:any)=>{
      if(result['id']>0){
        this._snackBar.open("Data Uploaded Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
      }
    })
  }

  delete(){}

  bulkDelete(){
    if(this.selection['_selected'].length > 0){
      const dialogRef = this.dialog.open(DeleteComponent,{
        data:{
          data:this.selection['_selected'],
          title:'Are you sure, you want to delete selected CAD RK Data?'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        // console.log(`Dialog result: ${result}`, result);
        if(result != undefined && result['status']){
          let dlist:any = []
          for(let i=0; i<this.selection['_selected'].length; i++){
            dlist.push(this.selection['_selected'][i].id);
          }
          this.auth.postAPI('delete-rkdata/',dlist).subscribe(res =>{
            console.log(res);
            this.areaFilter();
          })
          this._snackBar.open("CAD RK Data are successfully deleted",'', {duration: 5000, panelClass: ['successSnackbar']});
        }
      });
    }else{
      this._snackBar.open("Please select atleast 1 row to delete!",'', {duration: 5000, panelClass: ['alertSnackbar']});
    }
  }

  addCon(){
    const dialogRef = this.dialog.open(CadFormComponent,{
      data:{
        areas:this.areas,
        vans:this.vans,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      // let indexx = this.orders.findIndex(x => x.id === result.id);
      // this.orders[indexx] = result;
    });
  }

  bulkEdit(){
    if(this.selection['_selected'].length > 0){
      const dialogRef = this.dialog.open(CadBulkEditComponent,{
        data:{
          data:this.selection['_selected'],
          aread:this.areas,
          title:'Bulk Edit'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined && result['status']){
          this._snackBar.open("CAD RK Data are successfully Updated",'', {duration: 5000, panelClass: ['successSnackbar']});
          this.areaFilter();
        }
      });
    }else{
      this._snackBar.open("Please select atleast 1 row to edit!",'', {duration: 5000, panelClass: ['alertSnackbar']});
    }
  }

  datacal(){
    this.total = 0;
    this.forward = 0;
    this.scanned = 0;
    this.scannedp = 0;
    this.delivered = 0;
    this.deliveredp = 0;
    this.day1 = 0;
    this.day2 = 0;
    this.day3 = 0;
    this.day1p = 0;
    this.day2p = 0;
    this.day3p = 0;
    for(let j=0; j < this.orders.length; j++){
      this.total++;
      if(this.orders[j]['parent'] != null){ this.forward++; }
      if(this.orders[j]['scanned'] && this.orders[j]['van'] != null){ this.scanned++; }
      if(this.orders[j]['delivered'] && this.orders[j]['van'] != null){ this.delivered++; }
      if(this.orders[j]['vans'] == null){this.orders[j]['vans']={'title':''}}
      // this.orders[j]['delivery'] = new Date(this.orders[j]['delivery']);
      if(this.orders[j]['delivered'] && this.orders[j]['parent'] == null && this.orders[j]['deadline']==1){ this.day1++; }
      if(this.orders[j]['delivered'] && this.orders[j]['parent'] == null && this.orders[j]['deadline']==2){ this.day2++; }
      if(this.orders[j]['delivered'] && this.orders[j]['parent'] == null && this.orders[j]['deadline'] > 2){ this.day3++; }
    }
    if(this.total > 0){
      this.scannedp = Math.round((this.scanned/this.total)*100);
      this.deliveredp = Math.round((this.delivered/this.total)*100);
    }
    if((this.total - this.forward) > 0){
      this.day1p = Math.round((this.day1/(this.total-this.forward))*100);
      this.day2p = Math.round((this.day2/(this.total-this.forward))*100);
      this.day3p = Math.round((this.day3/(this.total-this.forward))*100);
    }
  }

  hrwupdate(event:any){
    if(event['hrw'].length > 0){
      this.auth.patchAPI('rkdata/'+event.id+'/',{'hrw':event['hrw']}).subscribe(result =>{
        event['saveHr']=false;
        this._snackBar.open("2HRW Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
      })
    }
  }

  focusOutAddress(event:any){
    if(event.address.trimStart().length > 0){
      this.auth.patchAPI('rkdata/'+event.id+'/',{'address':event.address}).subscribe(result =>{
        this._snackBar.open("Address Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
        // if(result['success']){
        //   this._snackBar.open("Address Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
        // }else{
        //   this._snackBar.open(result['results'],'', {duration: 50000, panelClass: ['alertSnackbar']});
        // }
        
      })
    }else{
      this.auth.patchAPI('rkdata/'+event.id+'/',{'address':null}).subscribe(result =>{
        this._snackBar.open("Address Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
      })
    }
  }

  focusOutVan(event:any){
    if(event['vans']['title'].trimStart().length > 0){
      this.auth.postAPI('update-vandata/',event).subscribe(result =>{
        if(result['success']){
          this._snackBar.open("VAN Details Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
        }else{
          this._snackBar.open(result['results'],'', {duration: 50000, panelClass: ['alertSnackbar']});
        }
        
      })
    }else{
      this.auth.patchAPI('rkdata/'+event.id+'/',{'van':null}).subscribe(result =>{
        this._snackBar.open("VAN Details Updated Successfully",'', {duration: 5000, panelClass: ['successSnackbar']});
      })
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: OrderData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  SortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  cprdata(){
    var ara = this.area?.id;
    if(ara == undefined){ara = '';}
    this.auth.getAPI('rkcpr/?date='+this.today+'&area='+ara).subscribe(result => {
      this.rkcpr = result['results'];
      this.cprChart.series[0].data = this.rkcpr['graph']['data'];
      this.cprChart.yAxis.plotLines = this.rkcpr['lines'];
      this.cprChart.xAxis.categories = this.rkcpr['graph']['months'];
      this.cprChart.colors = this.rkcpr['graph']['colours'];
      Highcharts.chart('cprChart',this.cprChart);
    });
  }

}
